import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetDriverApplication } from '../../infra/requests/DriversApplicationRequests';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer,
  DashboardContainer,
} from '../../styles/BasicStyles';
import {
  BasicInfoSection,
  SectionTitle,
  QuestionTitleContainer,
  QuestionTitle,
  ResponseSection,
} from './DriverApplicationStyles';
import { getLanguage } from '../../infra/utils/JobsApplication';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

class DriverApplicationDetails extends Component {
  state = {
    info: {},
    ready: false,
  };

  componentDidMount() {
    const { CloseMenu } = this.props;
    CloseMenu();
    
    this.getDriverApplication();
  }

  getDriverApplication = async () => {
    const {
      match: { params },
    } = this.props;
    const { data } = await GetDriverApplication(params.id);
    this.setState({ ready: true, info: data || {} });
  };

  handleOnCancel = () => {
    const { history } = this.props;
    history.push('/driversApplication');
  };

  printDetail = () => {
    window.print();
  }

  render() {
    const { ready, info } = this.state;
    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Candidatura Motorista</PageTitle>
            {moment.utc(info.createdAt).format('DD-MM-YYYY')}
          </HeaderTitle>
          <HeaderButtonsContainer className='no-print' buttons={3}>
            <BaseButton
              type='default'
              icon='mail'
              text='Responder'
              href={`mailto:${info?.personal?.email}?subject=Resposta%20Candidatura%20Motorista`}
            />
            <BaseButton
              type='default'
              icon='printer'
              text='Imprimir'
              onClick={this.printDetail}
            />
            <BaseButton
              type='default'
              icon='close'
              text='Cancelar'
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <DashboardContainer>
          <Row>
            <Col xs={24}>
              <BasicInfoSection>
                <SectionTitle>Dados Pessoais</SectionTitle>
                <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nome:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.name}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Morada:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.address}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Código-Postal:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.cep}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Localidade:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.locality}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Concelho:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.town}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Telemóvel:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.mobile_phone}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Telefone:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.phone}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Email:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.email}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Data de Nascimento:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.birthday && moment.utc(info.personal.birthday).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nacionalidade:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.nationality}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Estado Civil:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.civil_status}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Esposa/o trabalha:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.spouse_work === true ? 'Sim' : 'Não'}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nº de filhos a cargo:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.number_children}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Idade dos filhos:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.age_children}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nº da Carta de Condução:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.driving_license}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Emitido a:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.driving_license_issued && moment.utc(info.personal.driving_license_issued).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Válido até:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.driving_license_validity && moment.utc(info.personal.driving_license_validity).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nº da Cartão de Condutor:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.driver_card}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Emitido a:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.driver_card_issued && moment.utc(info.personal.driver_card_issued).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Válido até:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.driver_card_validity && moment.utc(info.personal.driver_card_validity).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>
                          Nº da Carta de Qualificação:
                        </QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.qualification_letter}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Emitido a:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.qualification_letter_issued && moment.utc(info.personal.qualification_letter_issued).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Válido até:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        { info?.personal?.qualification_letter_validity && moment.utc(info.personal.qualification_letter_validity).format('DD-MM-YYYY') }
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Carta ADR:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.adr === true ? 'Sim' : 'Não'}
                      </ResponseSection>
                    </Col>
                    {info?.personal?.adr_validity && (
                      <Col xs={8}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Válida até:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          { moment.utc(info.personal.adr_validity).format('DD-MM-YYYY') }
                        </ResponseSection>
                      </Col>
                    )}
                  </Row>
                </Col>
              </BasicInfoSection>
            </Col>
            {info?.professional_situation && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Situação profissional atual</SectionTitle>
                  <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                    <Row>
                      <Col xs={8}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Trabalha:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          {info?.professional_situation?.work === true
                            ? 'Sim'
                            : 'Não'}
                        </ResponseSection>
                      </Col>
                      {info?.professional_situation?.where && (
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>O que faz:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {info?.professional_situation?.where}
                          </ResponseSection>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={8}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Centro de emprego:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          {info?.professional_situation?.employment_center ===
                          true
                            ? 'Sim'
                            : 'Não'}
                        </ResponseSection>
                      </Col>
                      {info?.professional_situation?.date && (
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Desde quando:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            { moment.utc(info.professional_situation.date).format('DD-MM-YYYY') }
                          </ResponseSection>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Disponível a partir:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          { info?.professional_situation?.availability && moment.utc(info.professional_situation.availability).format('DD-MM-YYYY') }
                        </ResponseSection>
                      </Col>
                    </Row>
                    {info?.professional_situation?.observations && (
                      <Row>
                        <Col xs={24}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Observações:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {info?.professional_situation?.observations}
                          </ResponseSection>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </BasicInfoSection>
              </Col>
            )}
            {info?.professional_experience && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Experiência profissional</SectionTitle>
                  <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                    <Row>
                      <Col xs={24}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Viaturas a motor:</QuestionTitle>
                        </QuestionTitleContainer>
                        {info?.professional_experience?.motor.map(
                          (motor, index) => (
                            <ResponseSection marginB={10}>
                              {motor}
                            </ResponseSection>
                          )
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <QuestionTitleContainer style={{ marginTop: 20 }}>
                          <QuestionTitle>Viaturas com atrelados:</QuestionTitle>
                        </QuestionTitleContainer>
                        {info?.professional_experience?.trailer.map(
                          (trailer, index) => (
                            <ResponseSection marginB={10}>
                              {trailer}
                            </ResponseSection>
                          )
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <QuestionTitleContainer style={{ marginTop: 20 }}>
                          <QuestionTitle>Marcas de viaturas:</QuestionTitle>
                        </QuestionTitleContainer>
                        {info?.professional_experience?.brand.map(
                          (brand, index) => (
                            <ResponseSection marginB={10}>
                              {brand}
                            </ResponseSection>
                          )
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <QuestionTitleContainer style={{ marginTop: 20 }}>
                          <QuestionTitle>
                            Experiência no transporte internacional:
                          </QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          {info?.professional_experience
                            ?.international_transport === true
                            ? 'Sim'
                            : 'Não'}
                        </ResponseSection>
                      </Col>
                      {info?.professional_experience?.time && (
                        <Col xs={12}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Quanto tempo:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {info?.professional_experience?.time}
                          </ResponseSection>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Países:</QuestionTitle>
                        </QuestionTitleContainer>
                        {info?.professional_experience?.countries.map(
                          (country, index) => (
                            <ResponseSection marginB={10}>
                              {country}
                            </ResponseSection>
                          )
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <QuestionTitleContainer style={{ marginTop: 30 }}>
                          <QuestionTitle>
                            Disponibilidade para serviços expressos:
                          </QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>
                          {info?.professional_experience?.express_services ===
                          true
                            ? 'Sim'
                            : 'Não'}
                        </ResponseSection>
                      </Col>
                    </Row>
                    {info?.professional_experience?.observations && (
                      <Row>
                        <Col xs={24}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Observações:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {info?.professional_experience?.observations}
                          </ResponseSection>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </BasicInfoSection>
              </Col>
            )}
            {info?.local_work?.length > 0 && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Últimos locais onde trabalhou</SectionTitle>
                  {info?.local_work.map((work, index) => (
                    <Col
                      key={index}
                      xs={24}
                      style={{
                        background: '#F7F7F7',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Cargo:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{work.profession}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>
                              Nome e morada da empresa:
                            </QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{work.name_address}</ResponseSection>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Contacto:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{work.contact}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Tempo:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{work.time}</ResponseSection>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </BasicInfoSection>
              </Col>
            )}
            {info?.qualification?.length > 0 && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Escolaridade</SectionTitle>
                  {info?.qualification.map((school, index) => (
                    <Col
                      key={index}
                      xs={24}
                      style={{
                        background: '#F7F7F7',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Curso:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.course}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Entidade Formadora:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.university}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Duração:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.duration}</ResponseSection>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </BasicInfoSection>
              </Col>
            )}
            {info?.languages?.length > 0 && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Línguas Estrangeiras</SectionTitle>
                  {info?.languages.map((language, index) => (
                    <Col
                      key={index}
                      xs={24}
                      style={{
                        background: '#F7F7F7',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Língua:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {getLanguage(language.language)}
                          </ResponseSection>
                        </Col>
                        <Col xs={16}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Habilidade:</QuestionTitle>
                          </QuestionTitleContainer>
                          {language.speak === true && (
                            <Col xs={4}>
                              <ResponseSection>Fala</ResponseSection>
                            </Col>
                          )}
                          {language.understand === true && (
                            <Col xs={4}>
                              <ResponseSection>Compreende</ResponseSection>
                            </Col>
                          )}
                          {language.write === true && (
                            <Col xs={4}>
                              <ResponseSection>Escreve</ResponseSection>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </BasicInfoSection>
              </Col>
            )}
          </Row>
        </DashboardContainer>
      </React.Fragment>
    );
  }
}

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(null, mapActionToProps)(DriverApplicationDetails);