import React, { Component } from "react";
import { 
  PageContainer, 
  SpinLoading 
} from "../../styles/BasicStyles";
import { 
  TotalsContainer,
  TotalContainer, 
  TotalContent,
  IconContainer,
  TextContainer,
  Total,
  Text
} from "./DashboardStyles";
import { Icon } from 'antd';
import { GetBudgets } from '../../infra/requests/BudgetsRequests';
import { GetDriversApplication } from '../../infra/requests/DriversApplicationRequests';
import { GetJobsApplication, } from '../../infra/requests/JobsApplicationRequests';

class DashboardPage extends Component {
  state = {
    ready: false,
    totalBudgets: 0,
    totalDriversApplications: 0,
    totalJobsApplications: 0
  };

  componentDidMount() {
    this.getDashboardInfo();
  }

  getDashboardInfo = async () => {
    let totalBudgets = 0, totalDriversApplications = 0, totalJobsApplications = 0;

    let { data, success } = await GetBudgets(1, 10, '');
    if(success) {
      totalBudgets = data?.total;
    }

    ({ data, success } = await GetDriversApplication(1, 10, ''));
    if(success) {
      totalDriversApplications = data?.total;
    }

    ({ data, success } = await GetJobsApplication(1, 10, ''));
    if(success) {
      totalJobsApplications = data?.total;
    }

    this.setState({
      ready: true,
      totalBudgets,
      totalDriversApplications,
      totalJobsApplications
    });
  }

  renderTotal = (icon, text, total) => {
    return (
      <TotalContainer>
        <TotalContent>
          <IconContainer>
            <Icon style={{ fontSize: '40px' }} type={icon}/>
          </IconContainer>
          <TextContainer>
            <Total>{ total }</Total>
            <Text>{ text }</Text>
          </TextContainer>
        </TotalContent>
      </TotalContainer>
    );
  }

  render() {
    const { ready, totalBudgets, totalDriversApplications, totalJobsApplications } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <PageContainer>
        <h1>DASHBOARD</h1>
        <TotalsContainer>
          { this.renderTotal('euro', 'Pedidos de Orçamento', totalBudgets) }
          { this.renderTotal('snippets', 'Candidaturas', totalJobsApplications) }
          { this.renderTotal('car', 'Candidaturas Motoristas', totalDriversApplications) }
        </TotalsContainer>
      </PageContainer>
    );
  }
}

export default DashboardPage;