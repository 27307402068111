import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetPage,
  UpdatePage,
  CreatePage,
  SaveImage,
  DeleteImage,
} from '../../infra/requests/PagesRequests';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import {
  SectionTitle,
  QuestionTitle,
} from '../jobs_application/JobApplicationStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import VideoInput from '../../components/generic/inputs/VideoInput';
import TitleInput from '../../components/generic/inputs/TitleInput';
import LanguagesInput from '../../components/generic/inputs/LanguagesInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import PageValidations from './validations/PageValidations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

class ManagePages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if (params.id !== 'add') {
      const result = await GetPage(params.id);
      dispatch(initialize('manage_page_form', { ...result.data }));
      this.setState({
        ready: true,
        isNew: false,
        info: result || {},
      });
    } else {
      this.setState({
        ready: true,
        isNew: true,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      if (values.gallery.image && !values.gallery.image.blob) {
        delete values.gallery.image;
      }
      const payload = FlattenToFormData(values);

      const { success } = params.id
        ? await UpdatePage(params.id, payload)
        : await CreatePage(payload);

      if (success) return history.push('/pages');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/pages');
    } catch (e) {
      console.error(e);
    }
  };

  saveNewImage = async (image) => {
    const {
      match: { params },
    } = this.props;
    const payload = new FormData();
    payload.append('image', image.blob);
    return await SaveImage(params.id, payload);
  };

  deleteImage = async (image) => {
    const {
      match: { params },
    } = this.props;
    return await DeleteImage(params.id, image);
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, ready, info } = this.state;
    if (!ready) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Empresa', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <Row>
            <Col xs={8}>
              <QuestionTitle>{info.data.title}</QuestionTitle>
            </Col>
          </Row>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row xs={24}>
              <SectionTitle>Descrição</SectionTitle>
              <Col
                xs={info.data._id === '5ec54dd278f14307e2c87cf9' ? 7 : 12}
                offset={info.data._id === '5ec54dd278f14307e2c87cf9' ? 0 : 6}
              >
                <Field
                  component={LanguagesInput}
                  name={'description.first'}
                  type='draft'
                  toolbar='basic'
                  label={
                    info.data._id === '5ec54dd278f14307e2c87cf9'
                      ? 'Descrição 1ª coluna'
                      : 'Descrição'
                  }
                  placeholder={
                    info.data._id === '5ec54dd278f14307e2c87cf9'
                      ? 'Descrição 1ª coluna'
                      : 'Descrição'
                  }
                />
              </Col>
              {info.data._id === '5ec54dd278f14307e2c87cf9' && (
                <Col>
                  <Col xs={7} offset={1}>
                    <Field
                      component={LanguagesInput}
                      name={'description.second'}
                      type='draft'
                      toolbar='basic'
                      label={'Descrição 2ª coluna'}
                      placeholder={'Descrição 2ª coluna'}
                    />
                  </Col>
                  <Col xs={7} offset={1}>
                    <Field
                      component={LanguagesInput}
                      name={'description.third'}
                      type='draft'
                      toolbar='basic'
                      label={'Descrição 3ª coluna'}
                      placeholder={'Descrição 3ª coluna'}
                    />
                  </Col>
                </Col>
              )}
            </Row>
            {(info.data._id === '5ec54dd278f14307e2c87cf9' ||
              info.data._id === '5ec5086110de0824108d455f') && (
              <Row xs={24}>
                <SectionTitle>Sub-Descrição</SectionTitle>
                <Col xs={7} offset={0}>
                  <Field
                    component={TitleInput}
                    name={'subdescription[0].title'}
                    type='text'
                    label={'Título 1ª sub-descrição'}
                    placeholder={'Título 1ª sub-descrição'}
                  />
                  {info.data._id === '5ec54dd278f14307e2c87cf9' && (
                    <Field
                      component={LanguagesInput}
                      name={'subdescription[0].description'}
                      type='draft'
                      toolbar='basic'
                      label={'Descrição 1ª sub-descrição'}
                      placeholder={'Descrição 1ª sub-descrição'}
                    />
                  )}
                </Col>
                <Col xs={7} offset={1}>
                  <Field
                    component={TitleInput}
                    name={'subdescription[1].title'}
                    type='text'
                    label={'Título 2ª sub-descrição'}
                    placeholder={'Título 2ª sub-descrição'}
                  />
                  {info.data._id === '5ec54dd278f14307e2c87cf9' && (
                    <Field
                      component={LanguagesInput}
                      name={'subdescription[1].description'}
                      type='draft'
                      toolbar='basic'
                      label={'Descrição 2ª sub-descrição'}
                      placeholder={'Descrição 2ª sub-descrição'}
                    />
                  )}
                </Col>
                <Col xs={7} offset={1}>
                  <Field
                    component={TitleInput}
                    name={'subdescription[2].title'}
                    type='text'
                    label={'Título'}
                    placeholder={'Título'}
                  />
                  {info.data._id === '5ec54dd278f14307e2c87cf9' && (
                    <Field
                      component={LanguagesInput}
                      name={'subdescription[2].description'}
                      type='draft'
                      toolbar='basic'
                      label={'Descrição 2ª sub-descrição'}
                      placeholder={'Descrição 2ª sub-descrição'}
                    />
                  )}
                </Col>
              </Row>
            )}
            {info.data._id === '5ec5086110de0824108d455f' && (
              <Row xs={24}>
                <SectionTitle>Vídeo</SectionTitle>
                <Col xs={12} offset={6}>
                  <Field
                    component={VideoInput}
                    name='url'
                    type='text'
                    label='Url do Video'
                    sublabel='Pré-Visualizar'
                    placeholder='youtube ou vimeo link'
                  />
                </Col>
              </Row>
            )}
            {info.data._id === '5ec508c210de0824108d4561' && (
              <Row xs={24}>
                <SectionTitle>Características</SectionTitle>
                <Col xs={11} offset={0}>
                  <Field
                    component={TitleInput}
                    name={'characteristics[0].description'}
                    type='text'
                    label={'1ª característica'}
                    placeholder={'1ª característica'}
                  />
                  <Field
                    component={TitleInput}
                    name={'characteristics[1].description'}
                    type='text'
                    label={'2ª característica'}
                    placeholder={'2ª característica'}
                  />
                  <Field
                    component={TitleInput}
                    name={'characteristics[2].description'}
                    type='text'
                    label={'3ª característica'}
                    placeholder={'3ª característica'}
                  />
                </Col>
                <Col xs={11} offset={1}>
                  <Field
                    component={TitleInput}
                    name={'characteristics[3].description'}
                    type='text'
                    label={'4ª característica'}
                    placeholder={'4ª característica'}
                  />
                  <Field
                    component={TitleInput}
                    name={'characteristics[4].description'}
                    type='text'
                    label={'5ª característica'}
                    placeholder={'5ª característica'}
                  />
                  <Field
                    component={TitleInput}
                    name={'characteristics[5].description'}
                    type='text'
                    label={'6ª característica'}
                    placeholder={'6ª característica'}
                  />
                </Col>
              </Row>
            )}
            {info.data._id === '5ec5089810de0824108d4560' && (
              <Row xs={24}>
                <SectionTitle>Galeria</SectionTitle>
                <Col xs={5} offset={0}>
                  <Field
                    component={ImageInput}
                    name='gallery[0].image'
                    label='Imagem de capa'
                    ratio={0.6}
                  />
                  <Field
                    component={TitleInput}
                    name={'gallery[0].title'}
                    type='text'
                    label={'Título da imagem 1'}
                    placeholder={'Título da imagem 1'}
                  />
                </Col>
                <Col xs={5} offset={1}>
                  <Field
                    component={ImageInput}
                    name='gallery[1].image'
                    label='Imagem de capa'
                    ratio={0.6}
                  />
                  <Field
                    component={TitleInput}
                    name={'gallery[1].title'}
                    type='text'
                    label={'Título da imagem 1'}
                    placeholder={'Título da imagem 1'}
                  />
                </Col>
                <Col xs={5} offset={1}>
                  <Field
                    component={ImageInput}
                    name='gallery[2].image'
                    label='Imagem de capa'
                    ratio={0.6}
                  />
                  <Field
                    component={TitleInput}
                    name={'gallery[2].title'}
                    type='text'
                    label={'Título da imagem 1'}
                    placeholder={'Título da imagem 1'}
                  />
                </Col>
                <Col xs={5} offset={1}>
                  <Field
                    component={ImageInput}
                    name='gallery[3].image'
                    label='Imagem de capa'
                    ratio={0.6}
                  />
                  <Field
                    component={TitleInput}
                    name={'gallery[3].title'}
                    type='text'
                    label={'Título da imagem 1'}
                    placeholder={'Título da imagem 1'}
                  />
                </Col>
              </Row>
            )}
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePages = reduxForm({
  form: 'manage_page_form',
  validate: PageValidations,
})(ManagePages);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManagePages));