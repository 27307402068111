import client from '../config/AxiosConfig';

export const GetInfo = async () => client.get('/info');

export const GetTranslations = async () =>
  client.get('/translations/platform/BO');

export const GetDynamicPages = async () => client.get('/dynamic_pages');

export const GetDynamicPage = async (tag) =>
  client.get(`/dynamic_pages/${tag}`);

export const CreateDynamicPage = async (data) =>
  client.post('/dynamic_pages', data);

export const UpdateDynamicPage = async (tag, data) =>
  client.put(`/dynamic_pages/${tag}`, data);

export const GetMetatags = async () => client.get('/metatags');

export const GetMetatag = async (tag) =>
  client.get(`/metatags/${tag}`);

export const CreateMetatag = async (data) =>
  client.post('/metatags', data);

export const UpdateMetatag = async (tag, data) =>
  client.put(`/metatags/${tag}`, data);
