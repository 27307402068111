export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'home',
    exact: true,
  },
  {
    type: 'menu',
    name: 'Pedidos de Orçamento',
    to: '/budgets',
    icon: 'euro',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Candidaturas',
    to: '/jobsApplication',
    icon: 'snippets',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Candidaturas Motoristas',
    to: '/driversApplication',
    icon: 'car',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Recrutamento',
    to: '/jobs',
    icon: 'schedule',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Blog',
    to: '/blogs',
    icon: 'unordered-list',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Pág. Empresa',
    to: '/pages',
    icon: 'folder',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Páginas Dinâmicas',
    to: '/dynamic-pages',
    icon: 'copy',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Metatags',
    to: '/metatags',
    icon: 'tags',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Banners',
    to: '/banners',
    icon: 'file-image',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Traduções',
    to: '/translations',
    icon: 'global',
    exact: true,
  },
  {
    type: 'menu',
    name: 'Utilizadores',
    to: '/users',
    icon: 'team',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Informações Gerais',
    to: '/configs',
    icon: 'read',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Contactos',
    to: '/contacts',
    icon: 'message',
    exact: false,
  }
];