import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetContact,
  UpdateContact,
  CreateContact,
} from '../../infra/requests/ContactsRequests';
import TitleInput from '../../components/generic/inputs/TitleInput';
import TextInput from '../../components/generic/inputs/TextInput';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

const validations = FormValidator.make({
  title: 'required|languages',
  email: 'required',
  mobile_phone: 'required',
});

class ManageContactsPage extends Component {
  state = { isNew: false, loading: true };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();
    
    if (params.id) {
      const result = await GetContact(params.id);
      dispatch(
        initialize('manage_contacts_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false,
      });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      const payload = FlattenToFormData(values);

      const { success } = params.id
        ? await UpdateContact(params.id, payload)
        : await CreateContact(payload);

      if (success) return history.push('/contacts');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/contacts');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Contacts', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={16} offset={4}>
                <Field
                  component={TitleInput}
                  name={'title'}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título do contacto'}
                />
                <Field
                  component={TextInput}
                  name={'email'}
                  type='text'
                  label={'Email *'}
                  placeholder={'Email'}
                />
                <Field
                  component={TextInput}
                  name={'mobile_phone'}
                  type='text'
                  label={'Contacto *'}
                  placeholder={'Contacto'}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageContactsPage = reduxForm({
  form: 'manage_contacts_form',
  validate: validations,
})(ManageContactsPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageContactsPage));