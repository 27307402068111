import client from '../config/AxiosConfig';

export const GetContacts = async (currentPage, limit, filters) =>
  await client.get(`/contacts/${currentPage}/${limit}?filter=${filters}`);

// export const GetContacts = async (page = 1, limit = 12) =>
//   await client.get(`/contacts/${page}/${limit}`);

export const GetContact = async (id) => await client.get(`/contacts/${id}`);

export const CreateContact = async (data) =>
  await client.post(`/contacts`, data);

export const UpdateContact = async (id, data) =>
  await client.put(`/contacts/${id}`, data);

export const DeleteContact = async (id) =>
  await client.delete(`/contacts/${id}`);
