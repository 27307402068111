import React from "react";
import { connect } from "react-redux";
import { 
  StyledHeader, 
  StyledLogoContainer,
  StyleHeaderLine ,
  StyleUserContainer
} from "./LayoutStyles";

const HeaderComponent = ({ user }) => {
  return (
    <StyledHeader className='no-print'>
      <StyledLogoContainer>
        <StyleHeaderLine>Backoffice</StyleHeaderLine>
        <StyleHeaderLine>Transrendufense</StyleHeaderLine>
      </StyledLogoContainer>
      <StyleUserContainer>
        <StyleHeaderLine>Bem vindo,</StyleHeaderLine>
        <StyleHeaderLine>{ user.name }</StyleHeaderLine>
      </StyleUserContainer>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(HeaderComponent);