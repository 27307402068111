export const PrimaryColour = '#DC291B';
export const SecondaryColour = '#FAE017';
export const ThirdColour = '#3C2A97';
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColour = '#DC291B';
export const InputBorderColour = '#979797';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#DC291B';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#ffffff';
export const SecondaryBackgroundColor = '#8c8c8c';
export const ThirdBackgroundColor = '#cccccc';
export const HeaderBackgroundColor = '#e8e8e8';

// ef3c47
