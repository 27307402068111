import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import qs from 'query-string';
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import Logo from "../../assets/icons/logo.png";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  BaseLink
} from "./AuthStyles";
import { RegisterConfirmation } from '../../infra/requests/AuthRequests';
import { auth_token_key } from "../../infra/config/LocalStorageKeys";
import { SaveUser } from "../../redux/User/user.actions";


const formRules = FormValidator.make({
  email: "required|email",
  password: "required|min:6",
  repeat_password: "required|min:6|repeat_password",
});

class RegisterAccountPage extends Component {
  onSubmit = async values => {
    const { history, location } = this.props;
    try {
      const parsed = qs.parse(location.search);
      const token = parsed.token;

      const result = await RegisterConfirmation({ ...values, token });
      if (result.success) {
        localStorage.setItem(auth_token_key, result.data.token);
        SaveUser(result.data.user);
        history.push("/");
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>Nova conta</FormTitle>
            <Field
              component={TextInput}
              name="password"
              type="password"
              label={"Senha"}
            />
            <Field
              component={TextInput}
              name="repeat_password"
              type="password"
              label={"Repita a senha"}
            />
            <BaseLink to="/login">Já têm conta? Voltar para o login</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Criar"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

RegisterAccountPage = reduxForm({
  form: "register_account",
  validate: formRules
})(RegisterAccountPage);

const mapActionToProps = dispatch => bindActionCreators({ SaveUser }, dispatch);

export default connect(null, mapActionToProps)(RegisterAccountPage);