import PropTypes from 'prop-types';
import {withLocalize} from 'react-localize-redux';
import React from 'react';
import TextAreaInput from './TextAreaInput';
import DraftInput from './DraftInput';
import {
  LanguagesComponent,
  InputLabelDiv,
  FormCollapse,
  FormPanel
} from './InputStyles';

const getLabel = (label, code) => {
  if (code === 'en') return `${label} em Inglês`;
  if (code === 'pt') return `${label} em Português`;
  if (code === 'es') return `${label} em Espanhol`;
  if (code === 'fr') return `${label} em Francês`;
  return '';
};

const defaultObject = {pt: '', es: '', en: '', fr: ''};

const LanguagesInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  meta,
  errors,
  error,
  type,
  toolbar
}) => {
  //Reorder languages: some objects were saved in DB with the incorrect language order. Must be: pt, es, en, fr
  let orderedInputValue = null;
  if(input.value) {
    orderedInputValue = {};
    orderedInputValue.pt = input.value.pt;
    orderedInputValue.es = input.value.es;
    orderedInputValue.en = input.value.en;
    orderedInputValue.fr = input.value.fr;

    input.value = orderedInputValue;
  }
  
  const langs = Object.keys(input.value ? input.value : defaultObject);

  const showError = meta.invalid && meta.submitFailed;

  const applyError = key => {
    const exist = meta.error && Object.keys(meta.error).find(x => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const result =
      input && input.value && input.value !== ''
        ? {...input.value}
        : defaultObject;
    const value = event?.target ? event?.target?.value : event;
    result[code] = value;
    input.onChange(result);
  };

  const renderInput = code => {
    if (type === 'draft')
      return (
        <DraftInput
          noLabel
          meta={{
            invalid: applyError(code),
            submitFailed: meta.submitFailed
          }}
          placeholder={placeholder}
          input={{
            value: input?.value?.[code],
            onChange: value => changeInput(value, code)
          }}
          toolbar={toolbar}
        />
      );

    return (
      <TextAreaInput
        noLabel
        meta={{
          invalid: applyError(code),
          submitFailed: meta.submitFailed
        }}
        placeholder={placeholder}
        input={{
          value: input?.value?.[code],
          onChange: e => changeInput(e, code)
        }}
      />
    );
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      <FormCollapse accordion>
        {langs.map(code => (
          <FormPanel
            error={applyError(code)}
            header={getLabel(label, code)}
            key={code}>
            {renderInput(code)}
          </FormPanel>
        ))}
      </FormCollapse>
    </LanguagesComponent>
  );
};

LanguagesInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string
};

export default withLocalize(LanguagesInput);
