import styled from 'styled-components';

export const BannerTitle = styled.div`
  padding: 5px 0;
`;

export const ImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    padding: 10px;
    margin: 0 20px;
    font-size: 30px;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }
`;

export const OptionBox = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
`;

export const OptionTitle = styled.div`
  margin-top: 15px;
  color: #757575;
  font-size: 18px;
  height: 36px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const FloatButton = styled.div`
  cursor: pointer;
  position: absolute;
  background: white;
  padding: 5px;
  top: 6px;
  right: ${({ number }) => 6 + number * 30}px;
  box-shadow: 1px 1px 3px #ccc;
`;
