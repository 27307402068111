import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import { Popconfirm, Icon } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetContacts,
  DeleteContact,
} from '../../infra/requests/ContactsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ContactsFilters from './ContactsFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';

class ContactsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Contacto',
        dataIndex: 'mobile_phone',
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o contacto?'
                onConfirm={() => this.removeContact(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/contacts/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeContact = async (data) => {
    try {
      // todo: remove blog
      await DeleteContact(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetContacts(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Contactos</PageTitle>
            {`Contactos`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/contacts/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ContactsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='message'
            emptyText={'Não há contactos'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default ContactsPage;
