import client from '../config/AxiosConfig';

export const GetPages = async () => client.get('/pages');

export const GetPage = async (tag) => client.get(`/pages/${tag}`);

export const CreatePage = async (data) => client.post('/pages', data);

export const UpdatePage = async (tag, data) =>
  client.put(`/pages/${tag}`, data);

export const SaveImage = async (id, data) =>
  client.put(`/pages/${id}/image`, data);

export const DeleteImage = async (id, img_id) =>
  client.delete(`/pages/${id}/image/${img_id}`);
