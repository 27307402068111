import DashboardPage from '../../pages/dashboard/DashboardPage';
import UsersPage from '../../pages/users/UsersPage';
import ManageUsersPage from '../../pages/users/ManageUsersPage';
import BlogsPage from '../../pages/blog/BlogsPage';
import ManageBlogsPage from '../../pages/blog/ManageBlogsPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import Metatags from '../../pages/metatags/Metatags';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import ManageDynamicPages from '../../pages/dynamicPages/ManageDynamicPages';
import ManageMetatags from '../../pages/metatags/ManageMetatags';
import ConfigsPage from '../../pages/configs/ConfigsPage';
import ManageConfigsPage from '../../pages/configs/ManageConfigsPage';
import ContactsPage from '../../pages/contacts/ContactsPage';
import ManageContactsPage from '../../pages/contacts/ManageContactsPage';
import JobsPage from '../../pages/jobs/JobsPage';
import ManageJobsPage from '../../pages/jobs/ManageJobsPage';
import BannersPage from '../../pages/banners/BannersPage';
import ManageBannersPage from '../../pages/banners/ManageBannersPage';
import BudgetsPage from '../../pages/budget/BudgetsPage';
import BudgetDetails from '../../pages/budget/BudgetDetails';
import JobsApplicationPage from '../../pages/jobs_application/JobsApplicationPage';
import JobApplicationDetails from '../../pages/jobs_application/JobApplicationDetails';
import DriversApplicationPage from '../../pages/drivers_application/DriversApplicationPage';
import DriverApplicationDetails from '../../pages/drivers_application/DriverApplicationDetails';
import Pages from '../../pages/pages/Pages';
import ManagePages from '../../pages/pages/ManagePages';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/add',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:id',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blogs',
    component: BlogsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blogs/add',
    component: ManageBlogsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/blogs/:id',
    component: ManageBlogsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/metatags',
    component: Metatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/metatags/:id',
    component: ManageMetatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs',
    component: ConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs/:id',
    component: ManageConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/contacts',
    component: ContactsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/contacts/add',
    component: ManageContactsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/contacts/:id',
    component: ManageContactsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jobs',
    component: JobsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jobs/add',
    component: ManageJobsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jobs/:id',
    component: ManageJobsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/banners',
    component: BannersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/banners/add',
    component: ManageBannersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/banners/:id',
    component: ManageBannersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/budgets',
    component: BudgetsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/budgets/:id',
    component: BudgetDetails,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jobsApplication',
    component: JobsApplicationPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jobsApplication/:id',
    component: JobApplicationDetails,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/driversApplication',
    component: DriversApplicationPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/driversApplication/:id',
    component: DriverApplicationDetails,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/pages',
    component: Pages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/pages/:id',
    component: ManagePages,
    isAuthenticated: true,
    role: 1,
  },
];
