import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetConfig,
  UpdateConfigType,
} from '../../infra/requests/ConfigsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import { FormContainer, BaseForm, SpinLoading, Separator } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { TransformToFormData } from '../../infra/services/formdata/TransformToFormData';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

const validations = FormValidator.make({
  email: 'required|email',
  email_notifications_budget: 'required',
  email_notifications_job_application: 'required',
  email_notifications_driver_application: 'required',
  mobile_phone: 'required',
  fax: 'required',
  gps: 'required',
  latitude: 'required',
  longitude: 'required',
  address_line_1: 'required',
  complains: 'required',
  facebook_page: 'required',
  linkedin_page: 'required',
});

class ManageConfigsPage extends Component {
  state = {
    isNew: false,
    loading: true,
  };

  componentDidMount = async () => {
    try {
      const {
        dispatch,
        match: { params },
        CloseMenu
      } = this.props;

      CloseMenu();
      
      const configurations = await GetConfig(params.id);
      dispatch(
        initialize('manage_config_form', {
          ...configurations.data,
        })
      );
      this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        history,
        match: { params },
      } = this.props;
      const formDataValues = TransformToFormData(values);
      await UpdateConfigType(params.id, formDataValues);
      return history.push('/configs');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/configs');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Informações Gerais', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='email_notifications_budget'
              type='text'
              label={'Email para Notificações (Pedidos de Orçamento)'}
              placeholder={'Introduza o(s) emails(s) para receber as notificações'}
              notesText='Caso pretenda introduzir múltiplos emails, separe com "," (vírgula).'
            />
            <Field
              component={TextInput}
              name='email_notifications_job_application'
              type='text'
              label={'Email para Notificações (Candidaturas)'}
              placeholder={'Introduza o(s) emails(s) para receber as notificações'}
              notesText='Caso pretenda introduzir múltiplos emails, separe com "," (vírgula).'
            />
            <Field
              component={TextInput}
              name='email_notifications_driver_application'
              type='text'
              label={'Email para Notificações (Candidaturas a Motoristas)'}
              placeholder={'Introduza o(s) emails(s) para receber as notificações'}
              notesText='Caso pretenda introduzir múltiplos emails, separe com "," (vírgula).'
            />
            <Separator/>
            <Field
              component={TextInput}
              name='email'
              type='text'
              label={'Email'}
              placeholder={'Introduza o email geral da empresa'}
            />
            <Field
              component={TextInput}
              name='mobile_phone'
              type='text'
              label={'Telefone'}
              placeholder={'Introduza o nº de telefone da empresa'}
            />
            <Field
              component={TextInput}
              name='fax'
              type='text'
              label={'Fax'}
              placeholder={'Introduza o nº de fax da empresa'}
            />
            <Field
              component={TextInput}
              name='gps'
              type='text'
              label={'GPS'}
              placeholder={'Introduza as coordenadas GPS da empresa'}
            />
            <Field
              component={TextInput}
              name='latitude'
              type='text'
              label={'Latitude'}
              placeholder={'Introduza a latitude da empresa'}
            />
            <Field
              component={TextInput}
              name='longitude'
              type='text'
              label={'Longitude'}
              placeholder={'Introduza a longitude da empresa'}
            />
            <Field
              component={TextInput}
              name='address'
              type='text'
              label={'Morada'}
              placeholder={'Introduza a morada da empresa'}
            />
            <Field
              component={TextInput}
              name='cep'
              type='text'
              label={'Código Postal'}
              placeholder={'Introduza o código postal da empresa'}
            />
            <Field
              component={TextInput}
              name='country'
              type='text'
              label={'País'}
              placeholder={'Introduza o país da empresa'}
            />
            <Field
              component={TextInput}
              name='complains'
              type='text'
              label={'Página de Reclamações'}
              placeholder={'Introduza o link para o Livro de Reclamações eletrónico'}
            />
            <Field
              component={TextInput}
              name='facebook_page'
              type='text'
              label={'Página de Facebook'}
              placeholder={'Introduza o link da página do Facebook'}
            />
            <Field
              component={TextInput}
              name='linkedin_page'
              type='text'
              label={'Página de LinkedIn'}
              placeholder={'Introduza o link da página do LinkedIn'}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageConfigsPage = reduxForm({
  form: 'manage_config_form',
  validate: validations,
})(ManageConfigsPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageConfigsPage));