import client from '../config/AxiosConfig';

export const GetJobs = async (currentPage, limit, filters) =>
  await client.get(`/jobs/${currentPage}/${limit}?filter=${filters}`);

export const GetJob = async (identifier) =>
  await client.get(`/jobs/${identifier}`);

export const CreateJob = async (data) => await client.post(`/jobs`, data);

export const UpdateJob = async (identifier, data) =>
  await client.put(`/jobs/${identifier}`, data);

export const DeleteJob = async (identifier) =>
  await client.delete(`/jobs/${identifier}`);
