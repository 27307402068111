import styled, { createGlobalStyle } from 'styled-components';
import { Spin, Form as AntForm } from 'antd';
import { StyledButton } from '../components/generic/buttons/ButtonsStyles';
import { MenuBorderColor, AlertColour } from './Colours';
import { headerSize } from './Variables';

export const BasicStyles = createGlobalStyle`
  body {
    font-family: 'Roboto',  'sans-serif' !important;
  }

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

  .ant-popover{
    z-index: 99999 !important
  }

  .ant-menu-item {
    padding: 0px 10px !important;
  }

  .ant-notification {
    z-index: 9999999;
  }

  .ant-modal-mask {
    z-index: 999999;
  }

  .ant-modal-wrap {
    z-index: 9999999;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  top: ${headerSize};
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  z-index: 99999;
  border-bottom: 1px solid ${MenuBorderColor};
  align-items: center;

  @media print {
    top: 0px !important;
    padding: 10px !important;
  }
`;

export const HeaderTitle = styled.div`
  width: calc(
    100% - ${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')}
  );
  display: inline-block;
  text-align: left;

  @media print {
    width: 100% !important;
  }
`;

export const HeaderButtonsContainer = styled.div`
  width: calc(${({ buttons }) => (buttons ? buttons + ' * 170px' : '0px')});
  float: right;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }

  @media print {
    &.no-print, &.no-print * {
      display: none !important;
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 5px;
`;

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.sectionSize};
  color: ${({ theme }) => theme.thirdColor};
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({ subsection }) => (subsection ? '50px' : 0)};
`;

export const PageContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};

  @media print {
    padding: 10px !important;
  }
`;

export const TableButton = styled.div`
  display: inline-block;
  z-index: 999;
  padding: 5px;
  margin: 0 10px;

  &:hover {
    color: ${({ theme, primary, error }) =>
      primary
        ? theme.primaryColor
        : error
        ? theme.inputErrorColor
        : theme.thirdColor};
    font-weight: 700;
  }
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? '600px' : '100%')};
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${(p) => p.theme.primaryBackgroundColor};
  text-align: left;
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`;

export const DefaultLanguageTab = styled.div`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 12px 16px;
  margin-top: -2px;
  margin-bottom: 16px;
`;

export const DefaultSectionOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.inputFocusColor};
  border-radius: 4px;
  cursor: pointer;
  font-size: 28px;
  color: ${({ theme }) => theme.inputFocusColor};
  font-weight: bold;
  padding: 50px 15px;
  flex-direction: column;

  img {
    height: 50px;
  }

  span {
    display: block;
    margin-top: 10px;
    color: ${({ theme }) => theme.inputFocusColor};
    font-size: 18px;
    height: 36px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    box-shadow: 2px 2px 6px #ccc;
  }
`;

export const StyledPopContainer = styled.div`
  font-size: 10px;
  border: 1px solid ${MenuBorderColor};
  border-radius: 5px;
  width: 100%;
  text-align: center;
  padding: 3px;

  &:hover {
    color: ${AlertColour};
    border: 1px solid ${AlertColour};
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 20px;
`;

export const Separator = styled.hr`
  margin: 20px 0px;
`;

export const InputNote = styled.div`
  padding: 5px 0px;
  font-size: 13px;

  & span {
    font-weight: bold;
    color: #DC291B;
  }
`;