import styled from 'styled-components';
import { device } from "../../styles/Responsive";

export const TotalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0px -10px;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const TotalContainer = styled.div`
  flex-basis: 33.333%;
  padding: 10px;

  @media ${device.laptopL} {
    flex-basis: 100%;
  }
`;

export const TotalContent = styled.div`
  padding: 30px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media ${device.laptopL} {
    padding: 20px 10px;
  }
`;

export const IconContainer = styled.div`
  padding-right: 20px;

  @media ${device.laptopL} {
    padding-right: 10px;
  }
`;

export const TextContainer = styled.div`
  padding-left: 10px;
  border-left: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.laptopL} {
    padding-left: 5px;
  }
`;

export const Total = styled.div`
  font-size: 35px;
  padding-bottom: 5px;

  @media ${device.laptopL} {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  font-size: 14px;

  @media ${device.laptopL} {
    font-size: 13px;
  }
`;