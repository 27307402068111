import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetJob,
  UpdateJob,
  CreateJob,
} from '../../infra/requests/JobsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator,
  InputNote
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';
import DraftInput from '../../components/generic/inputs/DraftInput';
import TextInput from '../../components/generic/inputs/TextInput';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  identifier: 'required|noSpaces|noSpecialCharacter',
  title: 'required|languages',
  summary: 'required|languages',
  description: 'required|languages',
});

class ManageJobsPage extends Component {
  state = {
    isNew: false,
    loading: true,
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if (params.id) {
      const result = await GetJob(params.id);
      dispatch(initialize('manage_jobs_form', { ...result.data }));
      this.setState({ loading: false });
    } else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const payload = FlattenToFormData(values);

      const { success } = params.id
        ? await UpdateJob(params.id, payload)
        : await CreateJob(payload);

      if (success) {
        this.setState({ loading: false });
        return history.push('/jobs');
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/jobs');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Jobs', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título da Vaga'}
                />
                <Field
                  component={TextAreaInput}
                  name={`summary.${activeLanguage?.code}`}
                  label={'Resumo *'}
                  placeholder={'Resumo da Vaga'}
                />
                <Field
                  component={DraftInput}
                  name={`description.${activeLanguage?.code}`}
                  label={'Conteúdo *'}
                  placeholder={'Descrição da Vaga'}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          type='text'
                          label={'Título  *'}
                          placeholder={'Título do job'}
                        />
                        <Field
                          component={TextAreaInput}
                          name={`summary.${language.code}`}
                          label={'Resumo *'}
                          placeholder={'Resumo da Vaga'}
                        />
                        <Field
                          component={DraftInput}
                          name={`description.${language.code}`}
                          label={'Conteúdo *'}
                          placeholder={'Descrição da Vaga'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={`identifier`}
                  type='text'
                  label={'URL *'}
                  placeholder={'URL da Vaga'}
                />
                <InputNote><span>NOTA:</span> Deverá introduzir o URL sem espaços, sem acentos, letras minúsculas e apenas com letras, números e hífens.</InputNote>
                <InputNote><span>EXEMPLO:</span> Título da Vaga: "Nova Vaga para Recepção" / URL: "nova-vaga-para-recepcao"</InputNote>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`meta_title.${activeLanguage?.code}`}
                  label={'Meta Title'}
                  type={'text'}
                />
                <Field
                  component={TextAreaInput}
                  name={`meta_description.${activeLanguage?.code}`}
                  label={'Meta Description'}
                />
                <Field
                  component={TextAreaInput}
                  name={`meta_keywords.${activeLanguage?.code}`}
                  label={'Meta Keywords'}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`meta_title.${language.code}`}
                          label={'Meta Title'}
                          type={'text'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageJobsPage = reduxForm({
  form: 'manage_jobs_form',
  validate: validations,
})(ManageJobsPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageJobsPage));
