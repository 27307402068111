import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetJobsApplication,
  DeleteJobApplication,
} from '../../infra/requests/JobsApplicationRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import JobsApplicationFilters from './JobsApplicationFilters';
import moment from 'moment';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { getProfessionalArea } from '../../infra/utils/JobsApplication';

class JobsApplicationPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => moment.utc(value).format('DD-MM-YYYY'),
      },
      {
        title: 'Job',
        dataIndex: 'job.title',
        render: (value) => TranslateValue(value),
      },
      {
        title: 'Área Profissional',
        dataIndex: 'professional_area',
        render: (value) => getProfessionalArea(value),
      },
      {
        title: 'Nome do candidato',
        dataIndex: 'personal.name',
      },
      {
        title: 'Email do candidato',
        dataIndex: 'personal.email',
      },
      {
        title: 'Contacto do candidato',
        dataIndex: 'personal.mobile_phone',
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o blog?'
                onConfirm={() => this.removeJobApplication(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/jobsApplication/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeJobApplication = async (data) => {
    try {
      await DeleteJobApplication(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetJobsApplication(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Candidaturas</PageTitle>
            {`Candidaturas`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <JobsApplicationFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='snippets'
            emptyText={'Não há candidaturas'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default JobsApplicationPage;
