import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetDynamicPage,
  UpdateDynamicPage,
  CreateDynamicPage,
} from '../../infra/requests/BaseRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';
import DraftInput from '../../components/generic/inputs/DraftInput';
import VideoInput from '../../components/generic/inputs/VideoInput';
import TitleInput from '../../components/generic/inputs/TitleInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  description: 'required|languages',
});

class ManageDynamicPages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {},
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if (params.id !== 'add') {
      const result = await GetDynamicPage(params.id);
      dispatch(initialize('manage_dynamic_page_form', { ...result.data }));
      this.setState({
        ready: true,
        isNew: false,
        info: result || {},
      });
    } else {
      this.setState({
        ready: true,
        isNew: true,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      const { isNew } = this.state;

      isNew
        ? await CreateDynamicPage(values)
        : await UpdateDynamicPage(params.id, values);
      return history.push('/dynamic-pages');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/dynamic-pages');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, ready, info } = this.state;
    if (!ready) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Páginas Dinâmicas', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {info.data._id === '5ed12887ebf75d0702219ba2' && (
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={TitleInput}
                    name={'subtitle'}
                    type='text'
                    label={'Sub-título'}
                    placeholder={'Sub-título'}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage.code)}
                </DefaultLanguageTab>
                <Field
                  component={DraftInput}
                  name={`description.${activeLanguage.code}`}
                  label={'Descrição'}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={DraftInput}
                          name={`description.${language.code}`}
                          label={'Descrição'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
            {(info.data._id === '5ed124d7ebf75d0702219b9f' ||
              info.data._id === '5ed124fdebf75d0702219ba0' ||
              info.data._id === '5ed12887ebf75d0702219ba2' ||
              info.data._id === '5dc99bb1dc976d42f30f3698') && (
              <Col>
                <Row gutter={12}>
                  <Col xs={12}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={DraftInput}
                      name={`subdescription.${activeLanguage.code}`}
                      label={
                        info.data._id === '5ed12887ebf75d0702219ba2'
                          ? 'Sub-Descrição (Últimas Vagas)'
                          : 'Sub-Descrição'
                      }
                    />
                  </Col>
                  <Col xs={12}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              component={DraftInput}
                              name={`subdescription.${language.code}`}
                              label={
                                info.data._id === '5ed12887ebf75d0702219ba2'
                                  ? 'Sub-Descrição (Últimas Vagas)'
                                  : 'Sub-Descrição'
                              }
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
                {info.data._id === '5ed124d7ebf75d0702219b9f' && (
                  <Row gutter={12}>
                    <Col xs={12} offset={6}>
                      <Field
                        component={VideoInput}
                        name='url'
                        type='text'
                        label='Url do Video'
                        sublabel='Pré-Visualizar'
                        placeholder='youtube ou vimeo link'
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDynamicPages = reduxForm({
  form: 'manage_dynamic_page_form',
  validate: validations,
})(ManageDynamicPages);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageDynamicPages));