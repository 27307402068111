import React, { Component } from 'react';
import { reduxForm, Field, initialize } from 'redux-form';
import { FormContainer, BaseForm, SpinLoading } from '../../styles/BasicStyles';
import { Row, Col } from 'antd';
import {
  GetBanner,
  UpdateBanner,
  CreateBanner,
} from '../../infra/requests/BannersRequests.js';
import { withLocalize } from 'react-localize-redux';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

const validations = FormValidator.make({
  image: 'required'
});

class ManageBannerPage extends Component {
  state = {
    isNew: false,
    loading: false,
    ready: true,
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if (params.id) {
      const result = await GetBanner(params.id);
      dispatch(
        initialize('manage_banners_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } else
      this.setState({
        isNew: true,
        loading: false,
      });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      if (values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);

      const { success } = params.id
        ? await UpdateBanner(params.id, payload)
        : await CreateBanner(payload);

      if (success) return history.push('/banners');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/banners');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, ready, loading } = this.state;
    if (!ready || loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Banners', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={11} offset={0}>
                <Field
                  component={ImageInput}
                  name='image'
                  label='Imagem *'
                  ratio={0.6}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageBannerPage = reduxForm({
  form: 'manage_banners_form',
  validate: validations,
})(ManageBannerPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageBannerPage));