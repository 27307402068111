import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import { Row, Col, Pagination, message } from 'antd';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetBanners, DeleteBanner } from '../../infra/requests/BannersRequests';
import { OptionBox, FloatButton } from './BannersStyles';
import ImageComponent from '../../components/generic/images/ImageComponent';
class BannersPage extends Component {
  state = {
    pageSize: 12,
    currentPage: 1,
    loading: false,
    filters: '',
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetBanners(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  removeBanner = async (id) => {
    try {
      await DeleteBanner(id);
      await this.updateDataTable();
      message.success('Banner Eliminado');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { history } = this.props;
    const { rows, total, currentPage, pageSize } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Banners</PageTitle>
            {`Banners`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/banners/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Row gutter={[12, 12]}>
            {rows.map((banner) => (
              <Col key={banner._id} xs={12} sm={8} md={6}>
                <OptionBox>
                  <ImageComponent
                    color={banner?.image?.color || '#ccc'}
                    url={banner?.image?.url}
                    ratio={0.6}
                  />
                </OptionBox>
                <FloatButton number={0}>
                  <Popconfirm
                    placement='topRight'
                    title='De certeza que quer apagar esta opção?'
                    onConfirm={() => this.removeBanner(banner._id)}
                  >
                    <Icon type='delete' />
                  </Popconfirm>
                </FloatButton>
                <FloatButton
                  number={1}
                  onClick={() => history.push(`/banners/${banner._id}`)}
                >
                  <Icon type='edit' />
                </FloatButton>
              </Col>
            ))}
            <Col xs={24}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={total}
                onChange={this.handleChangePage}
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default BannersPage;
