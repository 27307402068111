import FormValidator from '../../../infra/services/validations/FormValidator';

const PageValidations = (values) => {
  const errors = {};
  if (values.id === '5ec54dd278f14307e2c87cf9') {
    errors.subdescription = values.subdescription.map((subdescription) => {
      return FormValidator.make({
        title: 'required|languages',
        description: 'required|languages',
      })(subdescription);
    });
  }
  if (values.id === '5ec5086110de0824108d455f') {
    errors.subdescription = values.subdescription.map((subdescription) => {
      return FormValidator.make({
        title: 'required|languages',
      })(subdescription);
    });
  }
  if (values.id === '5ec508c210de0824108d4561') {
    errors.characteristics = values.characteristics.map((characteristics) => {
      return FormValidator.make({
        description: 'required|languages',
      })(characteristics);
    });
  }
  if (values.id === '5ec5089810de0824108d4560') {
    errors.gallery = values.gallery.map((gallery) => {
      return FormValidator.make({
        image: 'required',
        title: 'required|languages',
      })(gallery);
    });
  }
  return errors;
};

export default PageValidations;
