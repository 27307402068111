import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { GetConfigs } from '../../infra/requests/ConfigsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import {
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
} from '../../styles/BasicStyles';
import { HeaderSection, SectionTitle, SectionAction } from './ConfigsStyles';
import InfoSection from './components/InfoSection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

class ConfigsPage extends Component {
  state = {
    configs: {},
  };

  componentDidMount = async () => {
    const { CloseMenu } = this.props;
    CloseMenu();
    
    await this.getConfigurations();
  };

  getConfigurations = async () => {
    await this.setState({ loading: true });
    try {
      const result = await GetConfigs();
      this.setState({
        configs: result.data[0],
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { history } = this.props;
    const { configs } = this.state;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Configurações</PageTitle>
            {`Configurações base do website`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Row gutter={24}>
            <Col xs={24} md={12} offset={6}>
              <HeaderSection>
                <SectionTitle>Informação Geral</SectionTitle>
                <SectionAction>
                  <BaseButton
                    type={'primary'}
                    icon={'edit'}
                    text={'Edit'}
                    onClick={() => history.push(`configs/${configs._id}`)}
                  />
                </SectionAction>
              </HeaderSection>
              <InfoSection
                title={'Email para Notificações (Pedidos de Orçamento)'}
                text={configs?.email_notifications_budget}
              />
              <InfoSection
                title={'Email para Notificações (Candidaturas)'}
                text={configs?.email_notifications_job_application}
              />
              <InfoSection
                title={'Email para Notificações (Candidaturas a Motoristas)'}
                text={configs?.email_notifications_driver_application}
              />
              <InfoSection title={'Email'} text={configs?.email} />
              <InfoSection title={'Telefone'} text={configs?.mobile_phone} />
              <InfoSection title={'Fax'} text={configs?.fax} />
              <InfoSection title={'GPS'} text={configs?.gps} />
              <InfoSection title={'Latitude'} text={configs?.latitude} />
              <InfoSection title={'Longitude'} text={configs?.longitude} />
              <InfoSection title={'Morada'} text={configs?.address} />
              <InfoSection title={'Código Postal'} text={configs?.cep} />
              <InfoSection title={'País'} text={configs?.country} />
              <InfoSection
                title={'Página de Reclamações'}
                text={configs?.complains}
              />
              <InfoSection
                title={'Página de Facebook'}
                text={configs?.facebook_page}
              />
              <InfoSection
                title={'Página de LinkedIn'}
                text={configs?.linkedin_page}
              />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(null, mapActionToProps)(ConfigsPage);