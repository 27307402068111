import client from "../config/AxiosConfig";

export const GetUser = async id => await client.get(`/users/${id}`);

export const GetUsers = async (currentPage, limit, filters) =>
  await client.get(`/users/${currentPage}/${limit}?filter=${filters}`);

export const UpdateUser = async (id, data) =>
  await client.put(`/users/${id}`, data);

export const CreateUser = async data => await client.post(`/users`, data);

export const DeleteUser = async id => await client.delete(`/users/${id}`);
