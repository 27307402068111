export const ProfessionalArea = [
  { _id: 1, name: 'Departamento Financeiro' },
  { _id: 2, name: 'Departamento de Manutenção' },
  { _id: 3, name: 'Departamento de Qualidade' },
];

export const getProfessionalArea = (value) => {
  switch (value) {
    case 1:
      return 'Departamento Financeiro';
    case 2:
      return 'Departamento de Manutenção';
    case 3:
      return 'Departamento de Qualidade';

    default:
      break;
  }
};

export const Schooling = [
  { _id: 1, name: '1º Ciclo' },
  { _id: 2, name: '2º Ciclo' },
  { _id: 3, name: '3º Ciclo' },
  { _id: 4, name: 'Ensino Secundário' },
  { _id: 5, name: 'Licenciatura' },
  { _id: 6, name: 'Mestrado' },
  { _id: 7, name: 'Doutoramento' },
];

export const getSchooling = (value) => {
  switch (value) {
    case 1:
      return '1º Ciclo';
    case 2:
      return '2º Ciclo';
    case 3:
      return '3º Ciclo';
    case 4:
      return 'Ensino Secundário';
    case 5:
      return 'Licenciatura';
    case 6:
      return 'Mestrado';
    case 7:
      return 'Doutoramento';

    default:
      break;
  }
};

export const Language = [
  { _id: 1, name: 'Inglês' },
  { _id: 2, name: 'Espanhol' },
  { _id: 3, name: 'Francês' },
  { _id: 4, name: 'Italiano' },
  { _id: 5, name: 'Alemão' },
];

export const getLanguage = (value) => {
  switch (value) {
    case 1:
      return 'Inglês';
    case 2:
      return 'Espanhol';
    case 3:
      return 'Francês';
    case 4:
      return 'Italiano';
    case 5:
      return 'Alemão';
    default:
      break;
  }
};
