import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetJobApplication } from '../../infra/requests/JobsApplicationRequests';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer,
  DashboardContainer,
} from '../../styles/BasicStyles';
import {
  BasicInfoSection,
  SectionTitle,
  QuestionTitleContainer,
  QuestionTitle,
  ResponseSection,
  FileSection,
  FloatButton,
} from './JobApplicationStyles';
import {
  getProfessionalArea,
  getSchooling,
  getLanguage,
} from '../../infra/utils/JobsApplication';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

class JobApplicationDetails extends Component {
  state = {
    info: {},
    ready: false,
  };

  componentDidMount() {
    const { CloseMenu } = this.props;
    CloseMenu();

    this.getJobApplication();
  }

  getJobApplication = async () => {
    const {
      match: { params },
    } = this.props;
    const { data } = await GetJobApplication(params.id);
    this.setState({ ready: true, info: data || {} });
  };

  handleOnCancel = () => {
    const { history } = this.props;
    history.push('/jobsApplication');
  };

  printDetail = () => {
    window.print();
  }

  render() {
    const { ready, info } = this.state;
    if (!ready) return <SpinLoading />;
    console.log('info', info);
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Candidatura</PageTitle>
            {moment.utc(info.createdAt).format('DD-MM-YYYY')}
          </HeaderTitle>
          <HeaderButtonsContainer className='no-print' buttons={3}>
            <BaseButton
              type='default'
              icon='mail'
              text='Responder'
              href={`mailto:${info?.personal?.email}?subject=Resposta%20Candidatura`}
            />
            <BaseButton
              type='default'
              icon='printer'
              text='Imprimir'
              onClick={this.printDetail}
            />
            <BaseButton
              type='default'
              icon='close'
              text='Cancelar'
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <DashboardContainer>
          <Row>
            {info?.job && (
              <Row>
                <Col xs={3}>
                  <QuestionTitle>Vaga:</QuestionTitle>
                </Col>
                <Col xs={4}>
                  <ResponseSection>
                    {TranslateValue(info.job.title)}
                  </ResponseSection>
                </Col>
              </Row>
            )}
            {info?.professional_area && (
              <Row>
                <Col xs={3}>
                  <QuestionTitle>Área profissional:</QuestionTitle>
                </Col>
                <Col xs={4}>
                  <ResponseSection>
                    {getProfessionalArea(info.professional_area)}
                  </ResponseSection>
                </Col>
              </Row>
            )}
            <Col xs={24}>
              <BasicInfoSection>
                <SectionTitle>Dados Pessoais</SectionTitle>
                <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nome:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.name}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Morada:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.address}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Telemóvel:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.personal?.mobile_phone}
                      </ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Email:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.personal?.email}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Escolaridade:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {getSchooling(info.personal.schooling)}
                      </ResponseSection>
                    </Col>
                  </Row>
                  {info?.personal?.cv_file && (
                    <Row>
                      <Col xs={6}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Curriculum Vitae:</QuestionTitle>
                        </QuestionTitleContainer>
                        <Row>
                          <Col xs={20}>
                            <FileSection>
                              {info?.personal?.cv_file?.filename}
                            </FileSection>
                          </Col>
                          <Col xs={4}>
                            <FloatButton
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_IMAGES_URL}${info?.personal?.cv_file?._id}`,
                                  '_blank'
                                )
                              }
                            >
                              Abrir
                            </FloatButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </BasicInfoSection>
            </Col>
            {info?.qualification?.length > 0 && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Escolaridade</SectionTitle>
                  {info?.qualification.map((school, index) => (
                    <Col
                      key={index}
                      xs={24}
                      style={{
                        background: '#F7F7F7',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Curso:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.course}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Entidade Formadora:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.university}</ResponseSection>
                        </Col>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Duração:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>{school.duration}</ResponseSection>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </BasicInfoSection>
              </Col>
            )}
            {info?.languages?.length > 0 && (
              <Col xs={24}>
                <BasicInfoSection>
                  <SectionTitle>Línguas Estrangeiras</SectionTitle>
                  {info?.languages.map((language, index) => (
                    <Col
                      key={index}
                      xs={24}
                      style={{
                        background: '#F7F7F7',
                        padding: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Row>
                        <Col xs={8}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Língua:</QuestionTitle>
                          </QuestionTitleContainer>
                          <ResponseSection>
                            {getLanguage(language.language)}
                          </ResponseSection>
                        </Col>
                        <Col xs={16}>
                          <QuestionTitleContainer>
                            <QuestionTitle>Habilidade:</QuestionTitle>
                          </QuestionTitleContainer>
                          {language.speak === true && (
                            <Col xs={4}>
                              <ResponseSection>Fala</ResponseSection>
                            </Col>
                          )}
                          {language.understand === true && (
                            <Col xs={4}>
                              <ResponseSection>Compreende</ResponseSection>
                            </Col>
                          )}
                          {language.write === true && (
                            <Col xs={4}>
                              <ResponseSection>Escreve</ResponseSection>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </BasicInfoSection>
              </Col>
            )}
          </Row>
        </DashboardContainer>
      </React.Fragment>
    );
  }
}

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(null, mapActionToProps)(JobApplicationDetails);