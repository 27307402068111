import client from '../config/AxiosConfig';

export const GetJobsApplication = async (currentPage, limit, filters) =>
  await client.get(
    `/jobsApplication/${currentPage}/${limit}?filter=${filters}`
  );

export const GetJobApplication = async (id) =>
  await client.get(`/jobsApplication/${id}`);

export const DeleteJobApplication = async (id) =>
  await client.delete(`/jobsApplication/${id}`);
