import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import React from 'react';
import { LanguagesComponent, InputLabelDiv, InputField } from './InputStyles';

const getLabel = (code) => {
  if (code === 'en') return 'Inglês';
  if (code === 'pt') return 'Português';
  if (code === 'es') return 'Espanhol';
  if (code === 'fr') return 'Francês';
  return '';
};

const defaultObject = { pt: '', es: '', en: '', fr: '' };

const verifyLanguages = (value) => {
  if (!value.pt) value.pt = '';
  if (!value.en) value.en = '';
  if (!value.es) value.es = '';
  if (!value.fr) value.fr = '';
  return value;
};

const TitleInput = ({
  label,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  disabled,
  meta,
  errors,
  error,
}) => {
  //Reorder languages: some objects were saved in DB with the incorrect language order. Must be: pt, es, en, fr
  let orderedInputValue = null;
  if(input.value) {
    orderedInputValue = {};
    orderedInputValue.pt = input.value.pt;
    orderedInputValue.es = input.value.es;
    orderedInputValue.en = input.value.en;
    orderedInputValue.fr = input.value.fr;

    input.value = orderedInputValue;
  }

  const langs = Object.keys(
    input.value ? verifyLanguages(input.value) : defaultObject
  );

  const showError = meta.invalid && meta.submitFailed;

  const applyError = (key) => {
    const exist = meta.error && Object.keys(meta.error).find((x) => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : defaultObject;
    const value = event?.target?.value;
    result[code] = value;
    input.onChange(verifyLanguages(result));
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      {langs.map((code) => (
        <InputField
          language={1}
          key={code}
          disabled={disabled}
          prefix={getLabel(code)}
          type='text'
          value={input.value[code]}
          onChange={(e) => changeInput(e, code)}
          placeholder={placeholder}
          error={applyError(code) && meta.submitFailed ? 1 : 0}
        />
      ))}
    </LanguagesComponent>
  );
};

TitleInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

export default withLocalize(TitleInput);
