import client from '../config/AxiosConfig';

export const GetBanners = async (page = 1, limit = 20, filter = '') =>
  client.get(`/banners/${page}/${limit}?filter=${filter}`);

export const GetBanner = async (bannerId) => client.get(`/banners/${bannerId}`);

export const UpdateBanner = async (bannerId, data) =>
  client.put(`/banners/${bannerId}`, data);

export const CreateBanner = async (data) => client.post(`/banners`, data);

export const DeleteBanner = async (bannerId) =>
  client.delete(`/banners/${bannerId}`);
