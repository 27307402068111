import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetBudget } from '../../infra/requests/BudgetsRequests';
import {
  SpinLoading,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer,
  DashboardContainer,
} from '../../styles/BasicStyles';
import {
  BasicInfoSection,
  SectionTitle,
  QuestionTitleContainer,
  QuestionTitle,
  ResponseSection,
} from './BudgetStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";

class BudgetDetails extends Component {
  state = {
    info: {},
    ready: false,
  };

  componentDidMount() {
    const { CloseMenu } = this.props;
    CloseMenu();
    
    this.getBudget(); 
  }

  getBudget = async () => {
    const {
      match: { params },
    } = this.props;
    const { data } = await GetBudget(params.id);
    this.setState({ ready: true, info: data || {} });
  };

  handleOnCancel = () => {
    const { history } = this.props;
    history.push('/budgets');
  };

  printDetail = () => {
    window.print();
  }

  render() {
    const { ready, info } = this.state;
    if (!ready) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Pedido de Orçamento</PageTitle>
            {moment.utc(info.date).format('DD-MM-YYYY')}
          </HeaderTitle>
          <HeaderButtonsContainer className='no-print' buttons={3}>
            <BaseButton
              type='default'
              icon='mail'
              text='Responder'
              href={`mailto:${info?.email}?subject=Resposta%20Pedido%20Orçamento`}
            />
            <BaseButton
              type='default'
              icon='printer'
              text='Imprimir'
              onClick={this.printDetail}
            />
            <BaseButton
              type='default'
              icon='close'
              text='Cancelar'
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <DashboardContainer>
          <Row>
            <Col xs={24}>
              <BasicInfoSection>
                <SectionTitle>Trajeto</SectionTitle>
                <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Data de Transporte:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {moment.utc(info?.date).format('DD-MM-YYYY')}
                      </ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Local de Carga:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.address_start}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>País:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.country_start}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Código Postal:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.cep_start}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Local de Descarga:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.address_end}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>País:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.country_end}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Código Postal:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.cep_end}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Requisitos de Viagem:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.number_drivers} motoristas
                      </ResponseSection>
                    </Col>
                  </Row>
                </Col>
              </BasicInfoSection>
            </Col>
            <Col xs={24}>
              <BasicInfoSection>
                <SectionTitle>Características</SectionTitle>
                <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>
                          Carga Completa ou Parcial:
                        </QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.full_partial}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Metragem:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.metreage}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Peso:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.weight}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>ADR:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>
                        {info?.adr === true ? 'Sim' : 'Não'}
                      </ResponseSection>
                    </Col>
                    {info?.number_ONU && (
                      <Col xs={8}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Número ONU:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>{info?.number_ONU}</ResponseSection>
                      </Col>
                    )}
                  </Row>
                  {info?.observations && (
                    <Row>
                      <Col xs={8}>
                        <QuestionTitleContainer>
                          <QuestionTitle>Observações:</QuestionTitle>
                        </QuestionTitleContainer>
                        <ResponseSection>{info?.observations}</ResponseSection>
                      </Col>
                    </Row>
                  )}
                </Col>
              </BasicInfoSection>
            </Col>
            <Col xs={24}>
              <BasicInfoSection>
                <SectionTitle>Informações Pessoais</SectionTitle>
                <Col style={{ background: '#F7F7F7', padding: '10px' }}>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nome da Empresa:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.company}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Nome Pessoa Contacto:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.name}</ResponseSection>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Telemóvel:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.mobile_phone}</ResponseSection>
                    </Col>
                    <Col xs={8}>
                      <QuestionTitleContainer>
                        <QuestionTitle>Email:</QuestionTitle>
                      </QuestionTitleContainer>
                      <ResponseSection>{info?.email}</ResponseSection>
                    </Col>
                  </Row>
                </Col>
              </BasicInfoSection>
            </Col>
          </Row>
        </DashboardContainer>
      </React.Fragment>
    );
  }
}

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default connect(null, mapActionToProps)(BudgetDetails);