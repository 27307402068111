import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../styles/BasicStyles';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetDynamicPages } from '../../infra/requests/BaseRequests';

class DynamicPages extends Component {
  state = {
    loading: false,
    columns: [{ title: 'Páginas', dataIndex: 'title' }],
    rows: [],
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/dynamic-pages/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetDynamicPages();
      this.setState({
        rows: result.data,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading } = this.state;
    // const { history } = this.props;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Páginas Dinâmicas</PageTitle>
            {`Páginas Dinâmicas`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            {/* <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/blogs/add')}
            /> */}
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon='copy'
            emptyText={'No Pages'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default DynamicPages;
