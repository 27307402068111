import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetBlog,
  UpdateBlog,
  CreateBlog,
  SaveImage,
  DeleteImage,
} from '../../infra/requests/BlogsRequests';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import DateInput from '../../components/generic/inputs/DateInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator,
  InputNote
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import DraftInput from '../../components/generic/inputs/DraftInput';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import moment from 'moment';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  identifier: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  summary: 'required|languages',
  description: 'required|languages',
  date: 'required',
  image: 'required',
});

class ManageBlogsPage extends Component {
  state = { isNew: false, loading: true };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if(params.id) {
      const result = await GetBlog(params.id);

      dispatch(initialize('manage_blogs_form', { ...result.data }));

      this.setState({ loading: false });
    } 
    else {
      dispatch(initialize('manage_blogs_form', { date: moment().utc(true) }));

      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      if (values.image && !values.image.blob) {
        delete values.image;
      }
      const payload = FlattenToFormData(values);

      const { success } = params.id
        ? await UpdateBlog(params.id, payload)
        : await CreateBlog(payload);

      if (success) return history.push('/blogs');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/blogs');
  };

  saveNewImage = async (image) => {
    const {
      match: { params },
    } = this.props;
    const payload = new FormData();
    payload.append('image', image.blob);
    return await SaveImage(params.id, payload);
  };

  deleteImage = async (image) => {
    const {
      match: { params },
    } = this.props;
    return await DeleteImage(params.id, image);
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading } = this.state;
    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Blogs', title]}
          buttons={[
            {
              title: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Field
                    component={ImageInput}
                    name='image'
                    label='Imagem de capa *'
                    ratio={0.6}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    component={DateInput}
                    name={'date'}
                    label={'Data *'}
                    placeholder={'Data do blog'}
                    defaultToday={true}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título do Post'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`summary.${activeLanguage?.code}`}
                    label={'Resumo *'}
                    placeholder={'Resumo'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Conteúdo *'}
                    placeholder={'Descrição'}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título do Post'}
                          />
                          <Field
                            component={TextAreaInput}
                            name={`summary.${language.code}`}
                            label={'Resumo *'}
                            placeholder={'Resumo'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Conteúdo *'}
                            placeholder={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Separator/>
              <Row>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name={`identifier`}
                    type='text'
                    label={'URL *'}
                    placeholder={'URL da Vaga'}
                  />
                  <InputNote><span>NOTA:</span> Deverá introduzir o URL sem espaços, sem acentos, letras minúsculas e apenas com letras, números e hífens.</InputNote>
                  <InputNote><span>EXEMPLO:</span> Título da Vaga: "Nova Vaga para Recepção" / URL: "nova-vaga-para-recepcao"</InputNote>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`meta_title.${activeLanguage?.code}`}
                    label={'Meta Title'}
                    type={'text'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_description.${activeLanguage?.code}`}
                    label={'Meta Description'}
                  />
                  <Field
                    component={TextAreaInput}
                    name={`meta_keywords.${activeLanguage?.code}`}
                    label={'Meta Keywords'}
                  />
                </Col>
                <Col xs={12}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`meta_title.${language.code}`}
                            label={'Meta Title'}
                            type={'text'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              {/* {!isNew && (
                <Col xs={20} offset={2}>
                  <SectionTitle subsection> Galeria de Imagens</SectionTitle>
                  <FieldArray
                    component={ImageGalleryInput}
                    name={'gallery'}
                    handleAdd={this.saveNewImage}
                    handleDelete={this.deleteImage}
                  />
                </Col>
              )} */}
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageBlogsPage = reduxForm({
  form: 'manage_blogs_form',
  validate: validations,
})(ManageBlogsPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageBlogsPage));