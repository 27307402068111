import client from '../config/AxiosConfig';

export const GetDriversApplication = async (currentPage, limit, filters) =>
  await client.get(
    `/driversApplication/${currentPage}/${limit}?filter=${filters}`
  );

export const GetDriverApplication = async (id) =>
  await client.get(`/driversApplication/${id}`);

export const DeleteDriverApplication = async (id) =>
  await client.delete(`/driversApplication/${id}`);
