import client from '../config/AxiosConfig';

export const GetBlogs = async (currentPage, limit, filters) =>
  await client.get(`/blogs/${currentPage}/${limit}?filter=${filters}`);

export const GetBlog = async (identifier) =>
  await client.get(`/blogs/${identifier}`);

export const ToggleBlogs = async (identifier, data) =>
  client.put(`/blogs/${identifier}/toggle`, data);

export const CreateBlog = async (data) => await client.post(`/blogs`, data);

export const UpdateBlog = async (identifier, data) =>
  await client.put(`/blogs/${identifier}`, data);

export const DeleteBlog = async (identifier) =>
  await client.delete(`/blogs/${identifier}`);

export const SaveImage = async (identifier, data) =>
  client.put(`/blogs/${identifier}/image`, data);

export const DeleteImage = async (identifier, img_id) =>
  client.delete(`/blogs/${identifier}/image/${img_id}`);
