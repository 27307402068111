import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import Logo from "../../assets/icons/logo.png";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle
} from "./AuthStyles";
import { Icon, notification } from "antd";
import { ResetPassword } from "../../infra/requests/AuthRequests";
import queryString from "query-string";

const ValidateResetForm = values => {
  const errors = {};
  errors.password = FormValidator.validateField(
    "password",
    values.password,
    "required|min:6"
  );
  errors.passwordconfirm = FormValidator.validateField(
    "passwordconfirm",
    values.passwordconfirm,
    "required|min:6"
  );
  if (values.password !== values.passwordconfirm) {
    errors.passwordconfirm = new Error("Invalid password");
  }
  return errors;
};

class ResetPasswordPage extends Component {
  onSubmit = async values => {
    const {
      location: { search },
      history
    } = this.props;
    const params = queryString.parse(search);
    const newParams = {
      password: values.password,
      token: params.token,
    };

    try {
      const result = await ResetPassword(newParams);
      if (result && result.success) {
        notification.success({
          message: "Senha alterada!",
          description:
            "Pode voltar a entrar com a nova senha."
        });
        history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>Redefinir a nova senha</FormTitle>
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="Nova senha"
              placeholder="Insira a nova senha"
              prefix={<Icon type="lock" />}
            />
            <Field
              component={TextInput}
              name="passwordconfirm"
              type="password"
              label="Confimar a nova senha"
              placeholder="Repita a nova senha"
              prefix={<Icon type="lock" />}
            />
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="submit"
                type="primary"
                loading={submitting}
                text="Alterar senha"
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

export default reduxForm({
  form: "reset_password",
  validate: ValidateResetForm
})(ResetPasswordPage);
