import styled from 'styled-components';

export const BasicInfoSection = styled.div`
  display: inline-block;
  width: 100%;
`;

export const SectionTitle = styled.div`
  display: inline-block;
  width: calc(100% + 30px);
  position: relative;
  font-size: 18px;
  color: white;
  background: ${({ theme }) => theme.primaryColor};
  text-align: left;
  margin: 30px -15px 10px -15px;
  padding: 10px 15px;

  @media print {
    margin: 10px -15px 10px -15px !important;
  }
`;

export const QuestionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const QuestionTitle = styled.h3`
  font-size: 16px;
  color: black;
  text-align: left;
  margin-bottom: 0;
`;

export const ResponseSection = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  font-size: 14px;
`;
